import { axiosAuthInstance } from '@core/axios/axios.authConfig';
import {
  PostBodyResetPasswordInterface,
  ResponseResetPasswordInterface
} from '@interface/ResetPasswordInterface';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

export const postResetPassword = async (
  body: PostBodyResetPasswordInterface
): Promise<ResponseResetPasswordInterface> => {
  const { data } = await axiosAuthInstance.post('/users/resetPassword', body);

  return data;
};

function usePostResetPassword(
  mutateFn?: UseMutationOptions<
    ResponseResetPasswordInterface,
    AxiosError<ResponseResetPasswordInterface>,
    PostBodyResetPasswordInterface
  >
) {
  return useMutation<
    ResponseResetPasswordInterface,
    AxiosError<ResponseResetPasswordInterface>,
    PostBodyResetPasswordInterface
  >(postResetPassword, mutateFn);
}

export default usePostResetPassword;
