import { axiosAuthInstance } from '@core/axios/axios.authConfig';
import {
  PostBodyCahngeEmailInterface,
  ResponseCahgneEmailInterface
} from '@interface/ChangeEmailInterface';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

export const postResetPassword = async (
  body: PostBodyCahngeEmailInterface
): Promise<ResponseCahgneEmailInterface> => {
  const { data } = await axiosAuthInstance.post('/users/changeEmail', body);

  return data;
};

function usePostChangeEmail(
  mutateFn?: UseMutationOptions<
    ResponseCahgneEmailInterface,
    AxiosError<ResponseCahgneEmailInterface>,
    PostBodyCahngeEmailInterface
  >
) {
  return useMutation<
    ResponseCahgneEmailInterface,
    AxiosError<ResponseCahgneEmailInterface>,
    PostBodyCahngeEmailInterface
  >(postResetPassword, mutateFn);
}

export default usePostChangeEmail;
