import { axiosAuthInstance } from '@core/axios/axios.authConfig';
import { PostBodyResetPinInterface, ResponseResetPinInterface } from '@interface/ResetPinInterface';
import { AxiosError } from 'axios';
import { useMutation, UseMutationOptions } from 'react-query';

export const postResetPassword = async (
  body: PostBodyResetPinInterface
): Promise<ResponseResetPinInterface> => {
  const { data } = await axiosAuthInstance.post('/users/resetPin', body);

  return data;
};

function usePostResetPin(
  mutateFn?: UseMutationOptions<
    ResponseResetPinInterface,
    AxiosError<ResponseResetPinInterface>,
    PostBodyResetPinInterface
  >
) {
  return useMutation<
    ResponseResetPinInterface,
    AxiosError<ResponseResetPinInterface>,
    PostBodyResetPinInterface
  >(postResetPassword, mutateFn);
}

export default usePostResetPin;
