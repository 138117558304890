import images from '@assets/images.json';
import { useI18n } from '@core/hooks/useI18n';
import { localStorageService } from '@core/utils/localStorage';
import usePostChangeEmail from '@hooks/usePostChangeEmail';
import LoginForm from '@layout/Form/LoginForm';
import ResetPasswordForm from '@layout/Form/ResetPasswordForm';
import ResetPinForm from '@layout/Form/ResetPinForm';
import Image from 'next/image';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseMenuDropdown,
  BaseText,
  LoadingIndicatorBox,
  showToast
} from 'uangcermat-web-toolkit-v2';

const LoginPage = () => {
  const { t } = useTranslation(['changeEmail', 'common']);
  const router = useRouter();
  const { tk: token, type } = router.query;
  const { changeLanguage } = useI18n();

  const TYPE_LOGIN_REDIRECT: Array<string> = ['password', 'pin', 'autologin'];

  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';

  const { mutate: mutateChangeEmail, isLoading: loadingChangeEmail } = usePostChangeEmail({
    onSuccess: (response) => {
      if (!response.error) {
        showToast({
          type: 'success',
          title: `${t('common:success')}!`,
          description: t('changeEmail:successMessage')
        });
        router.push('/login');
      }
    },
    onError: (response) => {
      const errorMessage = response.response?.data;
      let linkInvalid = false;
      if (errorMessage?.message && typeof errorMessage?.message === 'object') {
        for (let i = 0; i < Object.keys(errorMessage).length; i++) {
          if (Object.keys(errorMessage?.message)[i] === 'token') {
            linkInvalid = true;
          }
        }
      }

      if (linkInvalid) {
        showToast({
          type: 'error',
          title: `${t('common:error')}!`,
          description: t('changeEmail:expiredToken'),
          containerStyles: 'min-w-[370px]'
        });
        router.push('/login');
      }
    }
  });

  const handleChangeLanguage = (selected: LanguageType) => {
    changeLanguage(selected);
  };

  useEffect(() => {
    if (token && type == 'email') {
      mutateChangeEmail({
        token: token as string,
        lang: currentLanguage as LanguageType
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, type]);

  return (
    <div className="flex flex-row min-h-screen">
      {loadingChangeEmail && (
        <LoadingIndicatorBox color={process.env.LOADING_INDICATOR_COLOR} backdrop />
      )}
      <div className="flex w-420px justify-center items-center bg-gradient-radial-login from-loginPage-background-start to-loginPage-background-end">
        <div className="flex flex-col mt-[-18%] z-10">
          <Image
            src={images.login.leftLogo.url}
            width={images.login.leftLogo.width}
            height={images.login.leftLogo.height}
            alt="logo-peopleNote"
            className="self-center"
            loading="lazy"
          />
          <BaseText
            label={t('login:landingBannerText')}
            className="text-light-whiteSolid font-semibold text-[32px]"
          />
        </div>
        <div className="absolute w-420px h-screen flex ">
          <Image
            src={images.login.background.url}
            alt="logo-peopleNote"
            className="absolute opacity-1"
            loading="lazy"
            layout="fill"
          />
        </div>
      </div>
      <div className="w-[420px] h-screen flex flex-col gap-12 p-12 z-50 bg-white">
        <div className="flex flex-row items-center">
          <div className="flex flex-1">
            <Image
              src={images.login.rightLogo.url}
              width={images.login.rightLogo.width}
              height={images.login.rightLogo.height}
              alt="logo-peopleNote"
              className="w-[154px] h-10"
              loading="lazy"
            />
          </div>
          <BaseMenuDropdown
            data={[
              {
                label: 'ID',
                onSelectItem: () => {
                  handleChangeLanguage('id');
                }
              },
              {
                label: 'EN',
                onSelectItem: () => {
                  handleChangeLanguage('en');
                }
              }
            ]}
            containerStyles="w-14 h-[30px]"
            itemsContainerStyles="min-w-0 w-[55px]"
            activeBgColor={'var(--languageSwitcher-button-background-active)'}
            inactiveBgColor={'var(--languageSwitcher-button-background-default)'}
            activeLabelColor={'var(--languageSwitcher-button-textAndIcon-active)'}
            inactiveLabelColor={'var(--languageSwitcher-button-textAndIcon-default)'}
            activeIconColor={'var(--languageSwitcher-button-textAndIcon-active)'}
            inactiveIconColor={'var(--languageSwitcher-button-textAndIcon-default)'}
            hoverIconColor={'var(--languageSwitcher-button-textAndIcon-hover)'}
            label={currentLanguage.toUpperCase()}
            labelStyles="font-normal"
            position="bottom-end"
          />
        </div>
        {(!TYPE_LOGIN_REDIRECT.includes(type as string) || !token) && <LoginForm />}
        {(type as string) == 'pin' && token && <ResetPinForm />}
        {(type as string) == 'password' && token && <ResetPasswordForm />}
      </div>
    </div>
  );
};

export default LoginPage;
