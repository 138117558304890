import { useAuth } from '@context/AuthContext';
import { setAsyncTimeout } from '@core/utils/setAsyncTimeout';
import usePostResetPassword from '@hooks/usePostResetPassword';
import { ResetPasswordFormInterface } from '@interface/ResetPasswordInterface';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseButton,
  BaseText,
  BaseTextInput,
  LoadingIndicatorBox,
  showToast
} from 'uangcermat-web-toolkit-v2';
import * as Yup from 'yup';

const ResetPasswordForm = () => {
  const router = useRouter();
  const { tk: token } = router.query;
  const { t } = useTranslation(['resetPassword', 'common']);
  const [isShowPinPassword, setIsShowPinPassword] = useState<boolean>(false);
  const [isShowPinConfirmPassword, setIsShowPinConfirmPassword] = useState<boolean>(false);

  const { logout } = useAuth();

  const passwordRules = t('resetPassword:passwordRules');

  const resetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(t('common:fieldRequired'))
      .min(8, passwordRules)
      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/, passwordRules),
    confirmPassword: Yup.string()
      .required(t('common:fieldRequired'))
      .oneOf([Yup.ref('password')], t('resetPassword:passwordNotMatch'))
      .min(8, passwordRules)
      .matches(/^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/, passwordRules)
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: ''
    },
    validationSchema: resetPasswordSchema,
    validateOnChange: false,
    onSubmit: async (submitValue: ResetPasswordFormInterface) => {
      mutateResetPassword({ token: token as string, password: submitValue.password });
    }
  });

  const { mutate: mutateResetPassword, isLoading: loadingResetPassword } = usePostResetPassword({
    onSuccess: (response) => {
      if (!response.error && formik.values.password) {
        formik.setErrors({});
        showToast({
          type: 'success',
          title: `${t('common:success')}!`,
          description: t('resetPassword:passwordSuccessMessage')
        });
        router.push('/login');
      } else {
        if (response?.message && typeof response?.message === 'object') {
          for (let i = 0; i < Object.keys(response).length; i++) {
            formik.setFieldError(
              Object.keys(response?.message)[i],
              response?.message[Object.keys(response?.message)[i]]
            );
          }
        }
      }
    },
    onError: (response) => {
      const errorMessage = response.response?.data;
      let linkInvalid = false;
      if (errorMessage?.message && typeof errorMessage?.message === 'object') {
        for (let i = 0; i < Object.keys(errorMessage).length; i++) {
          if (Object.keys(errorMessage?.message)[i] === 'token') {
            linkInvalid = true;
          }
        }
      }

      if (linkInvalid) {
        showToast({
          type: 'error',
          title: `${t('common:error')}!`,
          description: t('resetPassword:expiredToken'),
          containerStyles: 'min-w-[370px]'
        });

        setAsyncTimeout(() => {
          logout();
        }, 500);
      }
    }
  });

  useEffect(() => {
    if (token) {
      mutateResetPassword({ token: token as string, validate: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <>
      {loadingResetPassword && (
        <LoadingIndicatorBox color={process.env.LOADING_INDICATOR_COLOR} backdrop />
      )}

      <div className="flex flex-col gap-4">
        <BaseText
          label={t('resetPassword:setNewPassword')}
          className="text-dark-blackCoral text-lg font-semibold"
        />
        <BaseTextInput
          type={isShowPinPassword ? 'text' : 'password'}
          id="password"
          name="password"
          htmlFor="password"
          value={formik.values.password}
          label={t('resetPassword:newPassword')}
          placeholder={t('resetPassword:enterNewPassword')}
          helperMessage={passwordRules}
          labelStyles="text-dark-gumbo text-[10px] font-normal"
          inputStyles="h-5"
          containerStyles="h-[30px]"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            formik.setFieldError('password', undefined);
            formik.setFieldValue('password', e.target.value);
          }}
          isError={formik.touched.password && !!formik.errors.password}
          errorMessage={formik.errors.password}
          rightIcon={isShowPinPassword ? 'eyeon' : 'eyeoff'}
          onClickRightIcon={() => setIsShowPinPassword(!isShowPinPassword)}
        />

        <BaseTextInput
          type={isShowPinConfirmPassword ? 'text' : 'password'}
          id="confirmPassword"
          name="confirmPassword"
          htmlFor="confirmPassword"
          value={formik.values.confirmPassword}
          label={t('resetPassword:confirmNewPassword')}
          placeholder={t('resetPassword:confirmYourNewPassword')}
          labelStyles="text-dark-gumbo text-[10px] font-normal"
          inputStyles="h-5"
          containerStyles="h-[30px]"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            formik.setFieldError('confirmPassword', undefined);
            formik.setFieldValue('confirmPassword', e.target.value);
          }}
          isError={formik.touched.confirmPassword && !!formik.errors.confirmPassword}
          errorMessage={formik.errors.confirmPassword}
          rightIcon={isShowPinConfirmPassword ? 'eyeon' : 'eyeoff'}
          onClickRightIcon={() => setIsShowPinConfirmPassword(!isShowPinConfirmPassword)}
        />
      </div>

      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2 w-full">
          <BaseButton
            variant="primary"
            label={t('common:button:save')}
            onClick={() => {
              formik.handleSubmit();
            }}
          />

          <BaseButton
            variant="secondary"
            label={t('common:button:cancel')}
            onClick={() => {
              router.push('/login');
            }}
          />
        </div>

        <div className="w-full flex justify-between items-center">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            width={74}
            height={22}
            src={
              'https://s3.ap-southeast-1.amazonaws.com/assets.frontend/new-toolkit/people-note-web/secure-ssl-encryption.png'
            }
          />
        </div>
      </div>
    </>
  );
};

export default ResetPasswordForm;
