import { useAuth } from '@context/AuthContext';
import { localStorageService } from '@core/utils/localStorage';
import { PostBodyLoginInterface } from '@interface/UserInterface';
import { useFormik } from 'formik';
import Image from 'next/image';
import { KeyboardEventHandler, useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BaseButton, BaseText, BaseTextInput, showToast } from 'uangcermat-web-toolkit-v2';
import * as Yup from 'yup';

const isDev = process.env.NODE_ENV === 'development';

const mockLogin = {
  email: '',
  password: ''
};

const LoginForm = () => {
  const { t } = useTranslation(['login', 'common']);
  const [isShowPinPassword, setIsShowPinPassword] = useState<boolean>(false);

  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';

  const { login, setErrorMessage, errorMessage } = useAuth();

  // @ts-expect-error: Disabling error checking for the next line
  const errEmail = errorMessage?.email;

  // @ts-expect-error: Disabling error checking for the next line
  const errPassword = errorMessage?.password;

  const loginSchema = Yup.object().shape({
    email: Yup.string().email(t('login:invalidEmail')).required(t('common:fieldRequired')),
    password: Yup.string().required(t('common:fieldRequired'))
  });

  const isShownToast = useRef(false);

  const formik = useFormik({
    initialValues: {
      lang: currentLanguage as LanguageType,
      email: isDev ? mockLogin.email : '',
      password: isDev ? mockLogin.password : ''
    },
    validationSchema: loginSchema,
    validateOnChange: false,
    onSubmit: async (submitValue: PostBodyLoginInterface) => {
      login({
        email: submitValue.email,
        password: submitValue.password,
        lang: submitValue.lang
      });
    }
  });

  useEffect(() => {
    formik.setFieldValue('lang', currentLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLanguage]);

  const toggleButtonOnEnter = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 13 || event.code === 'Enter') {
        formik.handleSubmit();
      } else if (formik.values.password && formik.values.email) {
        setErrorMessage('');
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formik]
  );

  useEffect(() => {
    document.addEventListener('keydown', toggleButtonOnEnter, false);

    return () => {
      document.removeEventListener('keydown', toggleButtonOnEnter, false);
    };
  }, [toggleButtonOnEnter]);

  return (
    <>
      <div className="flex flex-col gap-5">
        <BaseText label={t('login:login')} className="text-dark-blackCoral text-lg font-semibold" />

        <div className="flex flex-col w-full">
          <BaseTextInput
            type="text"
            id="email"
            name="email"
            htmlFor="email"
            value={formik.values.email}
            label={t('common:emailAddress')}
            inputStyles="h-5"
            containerStyles="h-[30px]"
            placeholder={t('login:enterEmail')}
            labelStyles="text-dark-gumbo text-[10px] font-normal"
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldError('email', '');
              setErrorMessage('');
            }}
            onKeyDown={toggleButtonOnEnter as unknown as KeyboardEventHandler<HTMLInputElement>}
            isError={(formik.touched.email && !!formik.errors.email) || !!errEmail || !!errPassword}
            errorMessage={formik.errors.email ?? (errEmail as string) ?? ''}
          />
        </div>

        <div className="flex flex-col w-full">
          <BaseTextInput
            type={isShowPinPassword ? 'text' : 'password'}
            id="password"
            name="password"
            htmlFor="password"
            value={formik.values.password}
            inputStyles="h-5"
            containerStyles="h-[30px]"
            label={t('login:password')}
            placeholder={t('login:enterPassword')}
            labelStyles="text-dark-gumbo text-[10px] font-normal"
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldError('password', '');
              setErrorMessage('');
            }}
            onKeyDown={toggleButtonOnEnter as unknown as KeyboardEventHandler<HTMLInputElement>}
            isError={(formik.touched.password && !!formik.errors.password) || !!errPassword}
            errorMessage={formik.errors.password ?? (errPassword as string) ?? ''}
            rightIcon={isShowPinPassword ? 'eyeon' : 'eyeoff'}
            onClickRightIcon={() => setIsShowPinPassword(!isShowPinPassword)}
          />
        </div>
      </div>

      <div className="flex flex-col gap-2 w-full">
        <BaseButton
          label={t('login:login')}
          className="w-full h-[30px] text-xs"
          onClick={() => {
            setErrorMessage('');
            formik.handleSubmit();
          }}
        />
        <div className="w-full flex justify-between items-center">
          <Image
            src={
              'https://s3.ap-southeast-1.amazonaws.com/assets.frontend/new-toolkit/people-note-web/secure-ssl-encryption.png'
            }
            width={74}
            height={33}
            alt="secure-ssl-encryption"
            loading="lazy"
          />
          <div
            onClick={() => {
              if (isShownToast.current) return;
              isShownToast.current = true;
              showToast({
                type: 'info',
                description: t('login:pleaseContactCsResetPassword'),
                duration: 5000,
                title: 'Info!'
              });
              setTimeout(() => {
                isShownToast.current = false;
              }, 5000);
            }}
          >
            <BaseText
              label={t('login:forgotPassword')}
              className="text-hyperlink-color-default text-xs font-normal cursor-pointer hover:underline decoration-hyperlink-color-default"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LoginForm;
