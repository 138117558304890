import { useAuth } from '@context/AuthContext';
import { localStorageService } from '@core/utils/localStorage';
import { setAsyncTimeout } from '@core/utils/setAsyncTimeout';
import usePostResetPin from '@hooks/usePostResetPin';
import { InputFormResetPinInterface } from '@interface/ResetPinInterface';
import { LanguageType } from '@interface/UserInterface';
import { useFormik } from 'formik';
import { useRouter } from 'next/router';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BaseButton,
  BaseText,
  BaseTextInput,
  LoadingIndicatorBox,
  showToast
} from 'uangcermat-web-toolkit-v2';
import * as Yup from 'yup';

const ResetPinForm = () => {
  const { t } = useTranslation(['resetPin', 'common']);
  const { logout } = useAuth();
  const router = useRouter();
  const { tk: token } = router.query;
  const [isShowNewPin, setIsShowNewPin] = useState<boolean>(false);
  const [isShowConfirmPin, setIsShowConfirmPin] = useState<boolean>(false);
  const [isShowPassword, setIsShowPassword] = useState<boolean>(false);

  const currentLanguage = (localStorageService.getLanguage('i18nextLng') as LanguageType) ?? 'en';

  const { mutate: mutateResetPin, isLoading: loadingResetPin } = usePostResetPin({
    // eslint-disable-next-line sonarjs/cognitive-complexity
    onSuccess: (response) => {
      if (!response.error && formik.values.pin) {
        formik.setErrors({});
        showToast({
          type: 'success',
          title: `${t('common:success')}!`,
          description: t('resetPin:successMessage')
        });
        router.push('/login');
      } else {
        if (response?.message && typeof response?.message === 'object') {
          for (let i = 0; i < Object.keys(response).length; i++) {
            if (Object.keys(response?.message)[0] === 'password') {
              formik.setFieldError('password', response?.message?.password);
            } else {
              formik.setFieldError(
                Object.keys(response?.message)[i],
                response?.message[Object.keys(response?.message)[i]]
              );
            }
          }
        }
      }
    },
    onError: (response) => {
      const errorMessage = response.response?.data;
      let linkInvalid = false;
      if (errorMessage?.message && typeof errorMessage?.message === 'object') {
        for (let i = 0; i < Object.keys(errorMessage).length; i++) {
          if (Object.keys(errorMessage?.message)[i] === 'token') {
            linkInvalid = true;
          }
        }
      }

      if (linkInvalid) {
        showToast({
          type: 'error',
          title: `${t('common:error')}!`,
          description: t('resetPin:expiredToken')
        });
        setAsyncTimeout(() => {
          logout();
        }, 500);
      }
    }
  });

  useEffect(() => {
    if (token) {
      mutateResetPin({
        validate: true,
        token: token as string,
        lang: currentLanguage
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const validationSchema = Yup.object().shape({
    pin: Yup.string()
      .matches(/^[0-9]{6}$/, t('resetPin:pin_must_be_6_digits_of_numbers'))
      .required(t('common:fieldRequired')),
    confirmPin: Yup.string()
      .matches(/^[0-9]{6}$/, t('resetPin:pin_must_be_6_digits_of_numbers'))
      .required(t('common:fieldRequired'))
      .oneOf([Yup.ref('pin'), ''], t('resetPin:Pins_do_not_match')),
    password: Yup.string().required(t('common:fieldRequired'))
  });

  const formik = useFormik({
    initialValues: {
      lang: currentLanguage as LanguageType,
      pin: '',
      confirmPin: '',
      password: ''
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: async (submitValue: InputFormResetPinInterface) => {
      mutateResetPin({
        token: token as string,
        pin: submitValue.pin,
        password: submitValue.password,
        lang: currentLanguage
      });
    }
  });

  return (
    <>
      {loadingResetPin && (
        <LoadingIndicatorBox color={process.env.LOADING_INDICATOR_COLOR} backdrop />
      )}

      <div className="flex flex-col gap-4">
        <BaseText
          label={t('resetPin:setNewPin')}
          className="text-dark-blackCoral text-lg font-semibold"
        />

        <BaseTextInput
          autoComplete="off"
          type={isShowNewPin ? 'text' : 'password'}
          id="pin"
          name="pin"
          htmlFor="pin"
          value={formik.values.pin}
          label={t('resetPin:newPin')}
          placeholder={t('resetPin:enterNewPin')}
          helperMessage={t('resetPin:pinHelper')}
          labelStyles="text-dark-gumbo text-[10px] font-normal"
          inputStyles="h-5"
          containerStyles="h-[30px]"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (
              (e.target.value.match(/^[0-9]+$/) || e.target.value === '') &&
              e.target.value.length <= 6
            ) {
              formik.setFieldError('pin', undefined);
              formik.setFieldValue('pin', e.target.value);
            }
          }}
          isError={formik.touched.pin && !!formik.errors.pin}
          errorMessage={formik.errors.pin}
          rightIcon={isShowNewPin ? 'eyeon' : 'eyeoff'}
          onClickRightIcon={() => setIsShowNewPin(!isShowNewPin)}
        />

        <BaseTextInput
          autoComplete="off"
          type={isShowConfirmPin ? 'text' : 'password'}
          id="confirmPin"
          name="confirmPin"
          htmlFor="confirmPin"
          value={formik.values.confirmPin}
          label={t('resetPin:confirmPin')}
          placeholder={t('resetPin:placeholderConfirmPin')}
          labelStyles="text-dark-gumbo text-[10px] font-normal"
          inputStyles="h-5"
          containerStyles="h-[30px]"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            if (
              (e.target.value.match(/^[0-9]+$/) || e.target.value === '') &&
              e.target.value.length <= 6
            ) {
              formik.setFieldError('confirmPin', undefined);
              formik.setFieldValue('confirmPin', e.target.value);
            }
          }}
          isError={formik.touched.confirmPin && !!formik.errors.confirmPin}
          errorMessage={formik.errors.confirmPin}
          rightIcon={isShowConfirmPin ? 'eyeon' : 'eyeoff'}
          onClickRightIcon={() => setIsShowConfirmPin(!isShowConfirmPin)}
        />

        <BaseTextInput
          autoComplete="off"
          type={isShowPassword ? 'text' : 'password'}
          id="password"
          name="password"
          htmlFor="password"
          value={formik.values.password}
          label={t('resetPin:password')}
          placeholder={t('resetPin:placeholderEnterPassword')}
          labelStyles="text-dark-gumbo text-[10px] font-normal"
          inputStyles="h-5"
          containerStyles="h-[30px]"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            formik.setFieldError('password', undefined);
            formik.setFieldValue('password', e.target.value);
          }}
          isError={formik.touched.password && !!formik.errors.password}
          errorMessage={formik.errors.password}
          rightIcon={isShowPassword ? 'eyeon' : 'eyeoff'}
          onClickRightIcon={() => setIsShowPassword(!isShowPassword)}
        />
      </div>

      <div className="flex flex-col gap-2">
        <BaseButton
          label={t('common:button:save')}
          variant="primary"
          className="w-full h-[30px] text-xs"
          onClick={() => {
            formik.handleSubmit();
          }}
        />
        <BaseButton
          label={t('common:button:cancel')}
          className="w-full h-[30px] text-xs"
          variant="secondary"
          onClick={() => {
            router.push('/login');
          }}
        />
        <div className="w-full flex justify-between items-center">
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            width={74}
            height={22}
            src={
              'https://s3.ap-southeast-1.amazonaws.com/assets.frontend/new-toolkit/people-note-web/secure-ssl-encryption.png'
            }
          />
        </div>
      </div>
    </>
  );
};

export default ResetPinForm;
